<script setup lang="ts">
import {
	formatAmount,
	BaseIcon,
	copyToClipboard,
} from '@legacy-studio/library';
import { useBonusesSelected } from '../infrastructure/store';
import { formatDate } from '~/src/shared/lib';

const bonusesInfo = useBonusesSelected();

// const activatePromocode = () => {
//   savePromocodeInfo(bonusesInfo.value.promocode);
//   openWidgetDrawer("topUp", { nested: false });
// };
</script>

<template>
	<div
		v-if="bonusesInfo"
		class="flex flex-col gap-6"
	>
		<p class="text-start text-sm font-normal text-gray-500">
			{{ bonusesInfo.description }}
		</p>

		<div>
			<div
				class="grid grid-cols-2 gap-3 py-3 border-b border-gray-600 cursor-pointer"
			>
				<div class="flex flex-col">
					<span class="text-xs pb-3 font-normal text-gray-500"> Начало </span>

					<span class="text-xs font-medium text-white">
						{{ formatDate(bonusesInfo.dateStart, `DD.MM.YYYY HH:mm`) }}
					</span>
					<span class="text-xs font-medium text-gray-500"> UTC+5 </span>
				</div>

				<div class="flex flex-col">
					<span class="text-xs pb-3 font-normal text-gray-500"> Конец </span>

					<span class="text-xs font-medium text-white">
						{{ formatDate(bonusesInfo.dateEnd, `DD.MM.YYYY HH:mm`) }}
					</span>
					<span class="text-xs font-medium text-gray-500"> UTC+5 </span>
				</div>
			</div>

			<div class="grid gap-3 py-3 border-b border-gray-600 cursor-pointer">
				<div class="flex flex-col">
					<span class="text-xs pb-3 font-normal text-gray-500">
						Размер бонуса
					</span>

					<span class="text-xs font-medium text-white">
						{{ bonusesInfo.sumTo }}
					</span>
				</div>
			</div>

			<div class="grid py-3 gap-3 border-b border-gray-600 cursor-pointer">
				<div class="flex flex-col">
					<span class="text-xs pb-3 font-normal text-gray-500">
						Сумма пополнения
					</span>

					<span class="text-xs font-medium text-white">
						{{
							`От ${formatAmount(bonusesInfo.sumFrom)} — до ${formatAmount(
								bonusesInfo.sumTo,
							)}`
						}}
					</span>
				</div>
			</div>

			<div class="grid py-3 gap-3 border-b border-gray-600 cursor-pointer">
				<div class="flex flex-col">
					<span class="text-xs pb-3 font-normal text-gray-500"> Промокод </span>

					<div class="flex justify-between">
						<span class="text-base font-extrabold text-white">
							{{ bonusesInfo.promocode }}
						</span>
						<button @click="() => copyToClipboard(bonusesInfo.promocode)">
							<BaseIcon
								name="document-duplicate"
								class="text-[16px]"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>

		<!--      <BaseButton -->
		<!--        size="sm" -->
		<!--        button-text="Участвовать в акции" -->
		<!--        class="!rounded-full w-100" -->
		<!--        color="dark-bb" -->
		<!--        @click="activatePromocode" -->
		<!--      /> -->
	</div>
</template>
